var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content',[_c('el-button-group',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('task.cluster_task_help'),"placement":"bottom-start"}},[_c('el-button',{attrs:{"icon":"el-icon-tickets"},on:{"click":function($event){return _vm.search('cluster')}}},[_vm._v(_vm._s(_vm.$t('task.cluster_task')))])],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('task.component_task_help'),"placement":"bottom-start"}},[_c('el-button',{attrs:{"icon":"el-icon-document-copy"},on:{"click":function($event){return _vm.search('component')}}},[_vm._v(_vm._s(_vm.$t('task.component_task')))])],1)],1),_c('complex-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.refresh,attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"fit":true},on:{"search":_vm.search}},[(_vm.searchForm.logtype === 'cluster')?_c('el-table-column',{attrs:{"sortable":"","prop":"tasklogs.type","min-width":"150","label":_vm.$t('cluster.detail.tag.task')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticStyle:{"font-size":"12px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.getDetailInfo(row)}}},[_vm._v(_vm._s(_vm.$t(("task." + (row.tasklogs.type)))))])]}}],null,false,1252270015)}):_vm._e(),(_vm.searchForm.logtype !== 'cluster')?_c('el-table-column',{attrs:{"sortable":"","prop":"tasklogs.type","min-width":"150","label":_vm.$t('cluster.detail.tag.task')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{staticStyle:{"font-size":"12px"},attrs:{"type":"info"},on:{"click":function($event){return _vm.openXterm(row)}}},[_vm._v(_vm._s(_vm.loadName(row)))])]}}],null,false,2565092422)}):_vm._e(),_c('el-table-column',{attrs:{"sortable":"","prop":"tasklogs.phase","label":_vm.$t('commons.table.status'),"min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.tasklogs.phase === 'FAILED')?_c('div',[_c('span',{staticClass:"iconfont iconerror",staticStyle:{"color":"#FA4147"}}),_vm._v(" "),_c('el-link',{attrs:{"type":"info"},on:{"click":function($event){return _vm.getStatus(row)}}},[_vm._v(_vm._s(_vm.$t("commons.status.FAILED")))])],1):_vm._e(),(row.tasklogs.phase === 'SUCCESS')?_c('div',[_c('span',{staticClass:"iconfont iconduihao",staticStyle:{"color":"#32B350"}}),_vm._v(" "+_vm._s(_vm.$t("commons.status.SUCCESS"))+" ")]):_vm._e(),(row.tasklogs.phase === 'RUNNING')?_c('span',[_c('i',{staticClass:"el-icon-loading"}),_vm._v(_vm._s(_vm.$t("commons.status.RUNNING"))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('commons.search.time_start'),"prop":"tasklogs.startTime","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("timeStampFormat")(row.tasklogs.startTime))+" ")]}}])}),_c('el-table-column',{attrs:{"sortable":"","label":_vm.$t('commons.search.time_end'),"prop":"tasklogs.endTime","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.tasklogs.endTime === 0)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm._f("timeStampFormat")(row.tasklogs.endTime)))])]}}])}),_c('el-table-column',{attrs:{"sortable":"","sort-method":_vm.sortFunc,"label":_vm.$t('commons.table.spend_time'),"min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.loadTimeSpend(row.tasklogs.startTime, row.tasklogs.endTime))+" ")]}}])})],1),_c('el-dialog',{attrs:{"title":_vm.$t('commons.button.error_msg'),"width":"50%","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('template',{slot:"title"},[(_vm.formatMsgs.failed)?_c('div',[_vm._v(_vm._s(_vm.formatMsgs.name))]):_vm._e()]),(_vm.formatMsgs.type !== 'unFormat')?_c('div',[_c('el-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[(_vm.formatMsgs.info.msg)?_c('el-collapse-item',{attrs:{"title":"message","name":"1"}},[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.formatMsgs.info.msg))])])]):_vm._e(),(_vm.formatMsgs.info.stderr)?_c('el-collapse-item',{attrs:{"title":"stderr","name":"2"}},[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.formatMsgs.info.stderr))])])]):_vm._e(),(_vm.formatMsgs.info.stdout)?_c('el-collapse-item',{attrs:{"title":"stdout","name":"3"}},[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.formatMsgs.info.stdout))])])]):_vm._e()],1)],1):_c('div',[_c('div',[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm._f("errorFormat")(_vm.formatMsgs.info)))])])]),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(_vm._s(_vm.$t("commons.button.cancel")))])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }